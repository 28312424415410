import { MsalProvider } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';

// MSAL imports

// ----------------------------------------------------------------------

export default function App({ pca }) {
  const { isInitialized, logout } = useAuth();

  // pca.addEventCallback((event) => {
  //   console.log(event);
  //   if (event.eventType === EventType.LOGOUT_SUCCESS) {
  //     logout();
  //   }
  // });

  return (
    <MsalProvider instance={pca}>
      <ThemeConfig>
        <ThemePrimaryColor>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <GlobalStyles />
                <ProgressBarStyle />
                <BaseOptionChartStyle />
                <ScrollToTop />
                <GoogleAnalytics />
                {isInitialized ? <Router /> : <LoadingScreen />}
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemePrimaryColor>
      </ThemeConfig>
    </MsalProvider>
  );
}
