import { useMsal } from '@azure/msal-react';

import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import windowsIcon from '@iconify/icons-whh/windows';
import { loginRequest } from '../authConfig';
import useAuth from '../hooks/useAuth';

export default function AzureButton({ type, loading = false, setError = null }) {
  const { loginAzure } = useAuth();
  const { instance } = useMsal();

  const handleLogin = async (loginType) => {
    try {
      if (loginType === 'popup') {
        await instance.loginPopup(loginRequest).then((val, rea) => {
          console.log(val);
          console.log(rea);
          const activeAccount = instance.getActiveAccount();
          handleLoginFederation(activeAccount);
        });
      } else if (loginType === 'redirect') {
        instance.loginRedirect(loginRequest);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = (logoutType) => {
    if (logoutType === 'popup') {
      instance.logoutPopup();
    } else if (logoutType === 'redirect') {
      instance.logoutRedirect();
    }
  };

  const handleLoginFederation = async (acc) => {
    try {
      loading(true);
      await loginAzure(acc.username, acc.idTokenClaims.nonce);
    } catch (error) {
      loading(false);
      setError(error);
      console.error(error);
    }
  };

  return (
    <Button
      onClick={() => (type === 'login' ? handleLogin('popup') : handleLogout('popup'))}
      sx={{ mt: 2, justifyContent: 'inherit' }}
      style={{ borderRadius: 0 }}
      variant="contained"
      color="primary"
      fullWidth
      disableRipple
      disableFocusRipple
      disableTouchRipple
      startIcon={
        <Box sx={{ borderRight: '1px solid #fff', display: 'flex' }}>
          <Icon icon={windowsIcon} style={{ marginRight: '8px' }} />
        </Box>
      }
    >
      {type === 'login' ? (
        <Box sx={{ textAlign: 'center', width: '100%' }}>Ingresar con Microsoft Federation</Box>
      ) : (
        <Box sx={{ textAlign: 'center', width: '100%' }}>Logout</Box>
      )}
    </Button>
  );
}
