const menuConfig = [
  {
    title: 'Dashboard',
    path: '/'
  },
  {
    title: 'Calendario',
    path: '/calendario'
  },
  {
    title: 'Resultados',
    // path: '/resultados'
    path: 'https://app.powerbi.com/reportEmbed?reportId=f7cce347-ef42-4d38-b69a-bfa229485816&autoAuth=true&ctid=5b268d57-2a6f-4e04-b0de-6938583d5ebc&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
  }
];

export default menuConfig;
