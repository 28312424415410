import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices

import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';

// custom slices for tracing app
import applicantReducer from './slices/applicant';
import templateReducer from './slices/template';
import eventReducer from './slices/event';
import specialityReducer from './slices/speciality';
import feeReducer from './slices/fee';
import divisionReducer from './slices/division';
import counterReducer from './slices/counter';
import solicitudReducer from './slices/solicitud';
import notificationReducer from './slices/notification';
import dashboardReducer from './slices/dashboard';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  applicant: applicantReducer,
  calendar: calendarReducer,
  counter: counterReducer,
  division: divisionReducer,
  event: eventReducer,
  notification: notificationReducer,
  product: productReducer,
  solicitud: solicitudReducer,
  speciality: specialityReducer,
  fee: feeReducer,
  template: templateReducer,
  user: userReducer,
  dashboard: dashboardReducer
});

export { rootPersistConfig, rootReducer };
