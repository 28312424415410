import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { isValidToken } from '../../utils/jwt';

const initialState = {
  isLoading: false,
  error: false,
  solicitudes: [],
  solicitud: {},
  solicitudesUsuario: [],
  solicitudUsuario: [],
  counter: []
};

const slice = createSlice({
  name: 'solicitud',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSolicitudesSuccess(state, action) {
      state.isLoading = false;
      state.solicitudes = action.payload;
    },

    getSolicitudSuccess(state, action) {
      state.isLoading = false;
      state.solicitud = action.payload;
    },

    getSolicitudesConsultorSuccess(state, action) {
      state.isLoading = false;
      state.solicitudesUsuario = action.payload;
    },

    getSolicitudConsultorSuccess(state, action) {
      state.isLoading = false;
      state.solicitudUsuario = action.payload;
    }
  }
});

export default slice.reducer;

export function getSolicitudes(request) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get('/solicitud/listado-admin', {
          params: request
        });

        // Enviar la data de las solicitudes que se encuentra en data
        dispatch(slice.actions.getSolicitudesSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSolicitud(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get('/solicitud/detail', {
          params: { id }
        });

        dispatch(slice.actions.getSolicitudSuccess(response.data));

        // Llamar a cargar los estados de la actividad
        // dispatch(getStatusRequest(response.data.tipoEnvio));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 *  Solicitudes de consultor
 */

export function getSolicitudesConsultor(request) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get('/solicitud/listado-consultor', {
          params: request
        });

        // Enviar la data de las solicitudes que se encuentra en data
        dispatch(slice.actions.getSolicitudesConsultorSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
