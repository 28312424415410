import { LogLevel } from '@azure/msal-browser';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * 
 *  Client ID: d2f82061-74dc-4261-932e-4e50d98ba198

        Tenant ID: 13f80aea-1087-4d3f-840d-6d693e9799d8
        Reply URL Configured: https://dev.d28wq6twsyxj65.amplifyapp.com/azure-sso
        OpenID Connect Metadata URL: https://login.microsoftonline.com/13f80aea-1087-4d3f-840d-6d693e9799d8/v2.0/.well-known/openid-configuration
        Scopes Authorized: User.Read, openid and profile

        cesar.duarte@abbottqa.com
        BetaMode2022@
 */

// Config object to be passed to Msal on creation

/** 
 * 
 * Connection Name: [6832] - EPD - Omni-UP - Dev

        Client ID: b55bacec-30de-480f-adee-7b48c8b932d3

        Tenant ID: 13f80aea-1087-4d3f-840d-6d693e9799d8
        Reply URL Configured: https://develop.dwqtxbcydvzpo.amplifyapp.com/validacion-federation
        OpenID Connect Metadata URL: https://login.microsoftonline.com/13f80aea-1087-4d3f-840d-6d693e9799d8/v2.0/.well-known/openid-configuration
        Scopes Authorized: User.Read, Profile.Read and openid.Read
*/

/*

 *  Miguel Modobeta: Connection Name: [6832] - EPD - Omni-UP - Dev

        Client ID: b55bacec-30de-480f-adee-7b48c8b932d3

        Tenant ID: 13f80aea-1087-4d3f-840d-6d693e9799d8
        Reply URL Configured: https://develop.dwqtxbcydvzpo.amplifyapp.com/validacion-federation
        OpenID Connect Metadata URL: https://login.microsoftonline.com/13f80aea-1087-4d3f-840d-6d693e9799d8/v2.0/.well-known/openid-configuration
        Scopes Authorized: User.Read, Profile.Read and openid.Read

4:34 pm., 4/2/2022Miguel Modobeta: Connection Name: [6831] - EPD - SuperApp - Dev

        Client ID: d2f82061-74dc-4261-932e-4e50d98ba198

        Tenant ID: 13f80aea-1087-4d3f-840d-6d693e9799d8
        Reply URL Configured: https://dev.d28wq6twsyxj65.amplifyapp.com/azure-sso
        OpenID Connect Metadata URL: https://login.microsoftonline.com/13f80aea-1087-4d3f-840d-6d693e9799d8/v2.0/.well-known/openid-configuration
        Scopes Authorized: User.Read, openid and profile
 * 
 * 
 */

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    redirectUri: process.env.REACT_APP_AZURE_LOGIN_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_AZURE_LOGOUT_REDIRECT_URL
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log('default');
        }
      }
    }
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read']
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me'
};
