import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

// material
import {
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
// routes

// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import AzureButton from '../../AzureButton';
import LoadingScreen from '../../LoadingScreen';
import { Block } from '../../../pages/components-overview/Block';

// ----------------------------------------------------------------------

const style = {
  minHeight: 'auto',
  marginBottom: 3
};
const RowContentStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

export default function LoginForm() {
  const { login } = useAuth();
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [loadingFederation, setLoadingFederation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Debe ser un correo válido').required('Email es obligatorio'),
    password: Yup.string().required('Contraseña es obligatoria')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        enqueueSnackbar('Bienvenido', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const SignInSignOutButton = () => {
    if (isAuthenticated) {
      return <AzureButton type="logout" />;
    }
    if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
      return <AzureButton type="login" loading={setLoadingFederation} setError={setErrorMessage} />;
    }
    return null;
  };

  return (
    <>
      {loadingFederation ? (
        <LoadingScreen
          sx={{
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            position: 'fixed'
          }}
        />
      ) : (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {/* <Stack spacing={3}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <FormControlLabel
                control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                label="Recordar"
              />
            </Stack>

            <LoadingButton sx={{ borderRadius: 0 }} fullWidth type="submit" variant="contained" loading={isSubmitting}>
              Ingresar
            </LoadingButton> */}

            <SignInSignOutButton />

            {errorMessage && (
              <Block sx={style}>
                <RowContentStyle>
                  <Typography gutterBottom variant="subtitle1" sx={{ color: 'error.main' }}>
                    Error
                  </Typography>
                  <Typography gutterBottom variant="body2" sx={{ color: 'error.main' }}>
                    {errorMessage.message}
                  </Typography>
                </RowContentStyle>
              </Block>
            )}
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
