import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { isValidToken } from '../../utils/jwt';

const initialState = {
  isLoading: false,
  error: false,
  notifications: []
};

const slice = createSlice({
  name: 'solicitud',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

export default slice.reducer;

export function getNotifications(request) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get('/notificacion/by-user', {
          params: request
        });

        // Enviar la data de las solicitudes que se encuentra en data
        dispatch(slice.actions.getNotificationsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
