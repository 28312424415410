import { sum, map, filter, uniqBy, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

// utils

import axios from '../../utils/axios';
import { isValidToken } from '../../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingActivity: false,
  error: false,
  products: [],
  applicants: [],
  applicant: [],
  updateActivity: [],
  counter: [],
  activity: [],
  health: [],
  observation: [],
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: ''
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null
  }
};

const slice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING ACITIVY
    startLoadingActivity(state) {
      state.isLoadingActivity = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // GET APPLICANTS
    getApplicantsSuccess(state, action) {
      state.isLoading = false;
      state.applicants = action.payload;
    },

    // GET APPLICANT
    getApplicantSuccess(state, action) {
      state.isLoading = false;
      state.applicant = action.payload;
    },

    updateActivityStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateActivity = action.payload;
    },

    sendObservationSuccess(state, action) {
      state.isLoading = false;
      state.observation = action.payload;
    },

    // GET COUNTERS
    getCounterSuccess(state, action) {
      state.isLoading = false;
      state.counter = action.payload;
    },

    // GET REQUEST STATUS
    getStatusRequestSuccess(state, action) {
      state.isLoadingActivity = false;
      state.activity = action.payload;
    },

    // GET HEALTH
    getHealthSuccess(state, action) {
      state.isLoading = false;
      state.health = action.payload;
    },

    // DELETE PRODUCT
    deleteProduct(state, action) {
      state.products = reject(state.products, { id: action.payload });
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((product) => product.price * product.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = filter(state.checkout.cart, (item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  deleteProduct,
  createBilling,
  applyShipping,
  applyDiscount,
  filterProducts,
  sortByProducts,
  increaseQuantity,
  decreaseQuantity
} = slice.actions;

// Counter Array
// Iterador que devuelve los contadores
export const counterArray = (counter) => [
  {
    id: 1,
    title: 'Pendientes',
    counter: counter.pendientes,
    backgroundColor: '#fff5e7',
    color: '#feaf3a'
  },
  {
    id: 2,
    title: 'Completadas',
    counter: counter.completadas,
    backgroundColor: '#defee3',
    color: '#1eab4f'
  },
  {
    id: 100,
    title: 'Con retraso',
    counter: counter.con_retraso,
    backgroundColor: '#ede5ee',
    color: '#6a2971'
  },
  {
    id: 200,
    title: 'Observadas',
    counter: counter.observadas,
    backgroundColor: '#fffbe4',
    color: '#024f6d'
  },
  {
    id: 0,
    title: 'Canceladas',
    counter: counter.canceladas,
    backgroundColor: '#fee2e4',
    color: '#d82934'
  }
];

export const TABLE_HEAD = [
  {
    id: 'idUsuario',
    label: 'Solicitante'
  },
  {
    id: 'campana',
    label: 'Campaña'
  },
  {
    id: 'idTemplate',
    label: 'Template'
  },
  {
    id: 'created_at',
    label: 'Fecha de registro'
  },
  {
    id: 'tipoEvento',
    label: 'Tipo'
  },
  {
    id: 'estado',
    label: 'Estado'
  },
  {
    id: 'mensaje',
    label: 'Observaciones'
  }
];
// ----------------------------------------------------------------------

export function getApplicants(request) {
  const { salud_plazo, actividad, campana, solicitante, mes, tipo, estado } = request;
  console.log(request);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get('/solicitud/listado-admin', {
          params: {
            actividad,
            campana,
            estado,
            mes,
            salud_plazo,
            solicitante,
            tipo
          }
        });
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        // Enviar la data de las solicitudes que se encuentra en data
        dispatch(slice.actions.getApplicantsSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCounter(request) {
  const { mes } = request;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get('/solicitud/contadores-home', {
          params: {
            mes
          }
        });

        // Enviar la data de las solicitudes que se encuentra en data
        dispatch(slice.actions.getCounterSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStatusRequest(request = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingActivity());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get('/actividad/all', {
          params: {
            tipo: encodeURIComponent(request)
          }
        });

        // Enviar la data de las solicitudes que se encuentra en data
        dispatch(slice.actions.getStatusRequestSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHealth() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get('/solicitud/obtener-estados');

        // Enviar la data de las solicitudes que se encuentra en data
        dispatch(slice.actions.getHealthSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getApplication(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/solicitud/detail', {
        params: { id }
      });
      dispatch(slice.actions.getApplicantSuccess(response.data));

      // Llamar a cargar los estados de la actividad
      dispatch(getStatusRequest(response.data.tipoEnvio));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Controlar la actualziacion de la solicitud
 *
 */

export function updateChangeActivity(solicitud, actividad) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post('/solicitud/cambiar-actividad', {
        id_solicitud: solicitud,
        id_actividad: actividad
      });
      dispatch(slice.actions.updateActivityStatusSuccess(response.data));
      dispatch(getApplication(solicitud));
    } catch (error) {
      console.error(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Agregar observaciones a la solicitud
 *
 */

export function sendObservationActivity(observacion, idActividad) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post('/solicitud/agregar-observacion', {
        observacion,
        id_solicitud_actividad: idActividad
      });
      dispatch(slice.actions.sendObservationSuccess(response.data));
    } catch (error) {
      console.error(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}
