// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const displayName = `${user.nombres} ${user.apellidos}`;

  return (
    <MAvatar alt={displayName} color={createAvatar(user.displayName).color} {...other}>
      {createAvatar(displayName).name}
    </MAvatar>
  );
}
