import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Container } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useOffSetTop from '../../hooks/useOffSetTop';
import { useCurrentRole } from '../../hooks/useCurrentRole';
import { Role } from '../../utils/enum';
// components
// import Logo from '../../components/Logo';
import LogoTipo from '../../components/LogoTipo';
import { MHidden } from '../../components/@material-extend';

import AccountPopover from './AccountPopover';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import navUserConfig from '../user/MenuConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 94;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const userRole = useCurrentRole();

  // TODO: Considerar el parametro para el home del usuario
  const isHome = pathname === '/home';
  const navRole = userRole === Role.User ? navUserConfig : navConfig;

  return (
    <>
      <AppBar sx={{ boxShadow: 0, bgcolor: 'white' }}>
        <ToolbarStyle
          disableGutters
          sx={{
            ...(isOffset && {
              bgcolor: 'background.default',
              height: { md: APP_BAR_DESKTOP - 16 }
            })
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <RouterLink to="/" style={{ textDecoration: 'none' }}>
              <LogoTipo
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '50px',
                  textAlign: 'center',
                  width: '175px',
                  marginRight: '1rem'
                }}
              />
            </RouterLink>

            <Box sx={{ flexGrow: 1 }} />

            <MHidden width="mdDown">
              <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navRole} />
            </MHidden>

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <AccountPopover />
            </Stack>

            <MHidden width="mdUp">
              <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navRole} />
            </MHidden>
          </Container>
        </ToolbarStyle>
        {isOffset && <ToolbarShadowStyle />}
      </AppBar>
    </>
  );
}
