import { Box, Container } from '@mui/material';
import Logo from '../../components/Logo';

export default function DashboardFooter() {
  return (
    <>
      <Box sx={{ boxShadow: 0, bgcolor: '#004F71', py: 2 }}>
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ width: 155, height: 40 }}>
            <img src="/static/brand/logoWhite.png" alt="Abbott" />
          </Box>
        </Container>
      </Box>
    </>
  );
}
