import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoTipo.propTypes = {
  sx: PropTypes.object
};

export default function LogoTipo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img src="/static/brand/LogoTipo.svg" alt="Onmi-Up" />
    </Box>
  );
}
