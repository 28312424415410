import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useCurrentRole } from '../hooks/useCurrentRole';

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array,
  children: PropTypes.node
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRole = useCurrentRole();

  if (!accessibleRoles.includes(currentRole)) {
    return <Navigate to="/solicitud" />;
  }

  return <>{children}</>;
}
