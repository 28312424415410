import useAuth from './useAuth';

/**
 *
 *  ok hay 3 roles
 *  SUPER admin, el que veo todo todo
 *  Admin,  quizas el q vea tablas de sistema y lo demas
 *  Usuario normal,  ese es el id rol 3,  es el q ve solo los dashboard y esas pantallas que tenemos
 *  admin@beta.pe   migcol@beta.pe     fernando@beta.pe
 */

import { Role } from '../utils/enum';

export const useCurrentRole = () => {
  // TODO: Agregar validación cuando no hay un usuario en useAuth
  const { user } = useAuth();

  let userRole = '';

  if (user.idRol === 1) {
    userRole = Role.RootAdmin;
  } else if (user.idRol === 2) {
    userRole = Role.Admin;
  } else userRole = Role.User;

  // Logic here to get current user role

  return userRole;
};
