import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import { Role } from '../utils/enum';

// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: 'auth',
      children: [
        {
          path: 'loginbeta',
          element: (
            <GuestGuard>
              <LoginBeta />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: 'azure-sso',
      element: (
        <GuestGuard>
          <LoginFede />
        </GuestGuard>
      )
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/resultados" replace /> },
        {
          path: 'resultados',
          element: <Metrics />
        }
      ]
    },
    {
      path: 'solicitud',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/solicitud/listado" replace /> },
        {
          path: 'listado',
          element: <ListRequest />
        },
        { path: 'detalle/:id', element: <Detail /> },
        { path: 'editar-detalle-email/:id', element: <DetailEditEmail /> },
        { path: 'editar-detalle-sms/:id', element: <DetailEditSms /> },
        { path: 'notificaciones', element: <Notifications /> },
        {
          path: 'nuevo',
          children: [
            { path: 'email', element: <AddEmail /> },
            { path: 'sms', element: <AddSMS /> }
          ]
        },
        {
          path: 'editar',
          children: [
            { path: 'email/:id', element: <AddEmail isEdit /> },
            { path: 'sms/:id', element: <AddSMS isEdit /> }
          ]
        }
      ]
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="404" replace /> }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={[Role.RootAdmin, Role.Admin]}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          element: <Dashboard />
        },
        { path: 'calendario', element: <Calendar /> },
        { path: 'resultados', element: <Metricsadmin /> },
        { path: 'resultados/detalle/:ola/:division', element: <MetricsDetail /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: 'metricas-user', element: <Metrics /> },
    { path: 'metricas-admin', element: <Metricsadmin /> }
  ]);
}

const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const LoginFede = Loadable(lazy(() => import('../pages/authentication/LoginFede')));
const LoginBeta = Loadable(lazy(() => import('../pages/authentication/LoginBeta')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

// Admin
const Dashboard = Loadable(lazy(() => import('../pages/admin/Dashboard')));
const Detail = Loadable(lazy(() => import('../pages/admin/Detail')));
const DetailEditEmail = Loadable(lazy(() => import('../pages/admin/DetailEditEmail')));
const DetailEditSms = Loadable(lazy(() => import('../pages/admin/DetailEditSms')));
const Metricsadmin = Loadable(lazy(() => import('../pages/dashboard/Metricsadmin')));
const MetricsDetail = Loadable(lazy(() => import('../pages/dashboard/MetricsDetail')));

// User
const ListRequest = Loadable(lazy(() => import('../pages/user/ListRequest')));
const Metrics = Loadable(lazy(() => import('../pages/dashboard/Metrics')));
const Notifications = Loadable(lazy(() => import('../pages/user/Notification')));
const AddSMS = Loadable(lazy(() => import('../pages/user/AddSMS')));
const AddEmail = Loadable(lazy(() => import('../pages/user/AddEmail')));
